<template>
<TextInput
    v-model="fieldValue"
    v-model:invalid="passwordInvalid"
    v-bind="$attrs"
    :type="reveal ? 'text' : 'password'"
    :class="{ invalid: passwordInvalid }"
    v-on:blur="validateValue"
    v-on:keydown="clearTypingTimer(typingTimer)"
    v-on:keyup="startTypingTimer(validateValue)"
>
    <template v-if="passwordInvalid" v-slot:flag>
        <FieldErrorFlag
            v-if="invalidationReason == 'passwordBlank'"
            error-text="Please enter a password."
        />
        <FieldErrorFlag
            v-else-if="invalidationReason == 'unauthorized'"
            error-text="Unauthorized."
        />
        <FieldErrorFlag
            v-else
            error-text="Please enter a valid password."
        />
    </template>
    <template v-slot:adornments>
        <span
            :key="reveal ? 'show' : 'hide'"
            role="button" tabindex="-1"
            class="password-toggle-icon"
            v-on:click="toggleReveal"
            v-on:keydown.enter="toggleReveal"
        >
            <i
                id="eyeball-icon"
                class="fa-regular"
                :class="{
                    'fa-eye-slash': !reveal,
                    'fa-eye': reveal
                }"
            />
        </span>
    </template>
    <template v-if="!noconfirm" v-slot:extra>
        <TextInput
            v-model="confirmValue"
            :type="reveal ? 'text' : 'password'"
            class="confirm"
            :label="$t('FORM.PASSWORD_SET_CONFIRM')"
            :class="{ invalid: confirmInvalid }"
            v-on:blur="validateConfirmValue"
            v-on:keydown="clearTypingTimer(typingTimer)"
            v-on:keyup="startTypingTimer(validateConfirmValue)"
        >
            <template v-if="invalid" v-slot:flag>
                <FieldErrorFlag
                    v-if="invalidationReason == 'confirmMissing'"
                    error-text="Please confirm your password."
                />
                <FieldErrorFlag
                    v-else-if="invalidationReason == 'confirmMismatch'"
                    error-text="Passwords must match."
                />
            </template>
            <template v-slot:adornments>
                <span
                    :key="reveal ? 'show' : 'hide'"
                    role="button" tabindex="-1"
                    class="password-toggle-icon"
                    v-on:click="toggleReveal"
                    v-on:keydown.enter="toggleReveal"
                >
                    <i
                        id="eyeball-icon"
                        class="fa-regular"
                        :class="{
                            'fa-eye': reveal,
                            'fa-eye-slash': !reveal
                        }"
                    />
                </span>
            </template>
            <template v-if="!novalidate" v-slot:hints>
                <Hint :status="passwordsMatch" label="Passwords must match" />
            </template>
        </TextInput>
    </template>
    <template v-if="!novalidate" v-slot:hints>
        <Hint :status="isSomeOrMoreCharacters" :label="minLength + '+ characters'" />
        <Hint :status="hasOneOrMoreNumbers" label="1 number" />
        <Hint :status="hasSpecialCharacter" label="Special character" />
    </template>
</TextInput>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TextInput from '$components/input/fields/TextInput.vue'
import FieldErrorFlag from '$components/input/FieldErrorFlag.vue'
import Hint from '$components/input/Hint.vue'

export default defineComponent({
    components: {
        TextInput,
        FieldErrorFlag,
        Hint
    },

    inheritAttrs: false,

    props: {
        value: String,
        modelValue: String,
        novalidate: Boolean,
        noconfirm: Boolean,
        minLength: {
            type: Number,
            default: 7
        },
        maxLength: {
            type: Number
        }
    },
    emits: [
        'input',
        'update:modelValue',
        'update:invalid',
        'update:invalidationReason',
        'keyup',
        'keydown',
        'focus',
        'blur'
    ],
    data() {
        return {
            fieldDirty: false,
            confirmValue: '',
            confirmDirty: false,
            reveal: false,
            invalidationReason: undefined as string | undefined,
            typingTimer: <ReturnType<typeof setTimeout> | null> null
        }
    },
    computed: {
        fieldValue: {
            get() {
                return this.modelValue
            },
            set(value: string) {
                this.fieldDirty = true
                this.$emit('update:modelValue', value)
            }
        },
        showPasswordIconClass() {
            return {
                'fa-eye-slash': !this.reveal,
                'fa-eye': this.reveal
            }
        },
        isSomeOrMoreCharacters() {
            if (this.fieldValue.length === 0) return undefined
            return this.fieldValue.length > this.$props.minLength ? 'pass' : 'fail'
        },
        hasOneOrMoreNumbers() {
            if (this.fieldValue.length === 0) return undefined
            return /\d/.test(this.fieldValue) ? 'pass' : 'fail'
        },
        hasSpecialCharacter() {
            if (this.fieldValue.length === 0) return undefined
            const specialCharPattern = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/
            return specialCharPattern.test(this.fieldValue) ? 'pass' : 'fail'
        },
        passwordsMatch() {
            if (this.confirmValue.length === 0) return undefined
            return this.fieldValue === this.confirmValue ? 'pass' : 'fail'
        },
        passwordInvalid() {
            return (
                this.isSomeOrMoreCharacters === 'fail'
                || this.hasOneOrMoreNumbers === 'fail'
                || this.hasSpecialCharacter === 'fail'
            )
        },

        confirmInvalid() {
            if (!this.noconfirm) {
                if (this.confirmValue.length > 0) {
                    return this.confirmValue !== this.fieldValue
                }
                return true
            }
            return false
        },

        invalid() {
            return this.passwordInvalid || this.confirmInvalid
        }
    },
    watch: {
        invalid(newValue: boolean) {
            this.$emit('update:invalid', newValue)
        },
        invalidationReason() {
            this.$emit('update:invalidationReason', this.invalidationReason)
        }
    },
    methods: {
        toggleReveal() {
            this.reveal = !this.reveal
        },
        validateValue() {
            if (!this.fieldDirty || this.$props.novalidate) return

            this.invalidationReason = undefined

            if (this.fieldValue.length === 0) {
                this.invalidationReason = 'passwordBlank'
            } else if (this.isSomeOrMoreCharacters === 'fail') {
                this.invalidationReason = 'tooShort'
            } else if (this.hasOneOrMoreNumbers === 'fail') {
                this.invalidationReason = 'hasNumbers'
            } else if (this.hasSpecialCharacter === 'fail') {
                this.invalidationReason = 'hasNoSpecialCharacter'
            }
        },
        validateConfirmValue() {
            if (this.$props.novalidate) return

            this.invalidationReason = undefined
            if (['confirmMissing'].includes(this.invalidationReason)) {
                this.invalidationReason = undefined
            }

            if (this.confirmValue.length > 0) {
                this.invalidationReason = 'confirmMissing'
            }
        },
        clearTypingTimer(timer: ReturnType<typeof setTimeout> | null) {
            if (!timer) return
            clearTimeout(timer)
        },

        startTypingTimer(fn: () => void) {
            this.clearTypingTimer(this.typingTimer)
            const DONE_TYPING_INTERVAL = 1500
            this.invalid = false
            this.typingTimer = setTimeout(fn, DONE_TYPING_INTERVAL)
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss";

.password-toggle-icon {
    display: inline-block;
    cursor: pointer;
}
</style>
