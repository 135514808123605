<template>
<span class="hint">
    <span :key="status" class="status" :data-status="status">
        <i class="fas" :class="iconKey" />
    </span>
    <span class="label">{{ label }}</span>
</span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export type HintStatus = 'pass' | 'fail' | undefined

interface Props {
    status?: HintStatus
    label?: string
    info?: string
}

const props = defineProps<Props>()

const iconKey = computed(() => (
    props.status === 'fail'
        ? 'fa-exclamation'
        : 'fa-check'
))

export type { Props }
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss";

.hint {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.hint+.hint {
    margin-left: 1em;
}

.status {
    display: inline-block;
    background-color: #b8c0cc;
    padding: 0.2em;
    margin-right: 0.35em;
    width: 1.4em;
    height: 1.4em;
    font-size: 0.7em;
    text-align: center;
    color: var(--shade-100);
    border-radius: 20em;
    vertical-align: middle;

    &[data-status='pass'] {
        background-color: #90cd93;
    }

    &[data-status='fail'] {
        background-color: #ff8980;
    }

    &>.icon {
        display: none;
        vertical-align: middle;
    }
}
</style>
